import axios from 'axios'
import { returnData, config, catchError } from './api-utils'
import { fetchEventSource } from '@microsoft/fetch-event-source'

const createReportSelection = (action) => {
  const { companyCode, reportSelection, reportSelectionId } = action
  const baseUrl = `${companyCode || ''}/reportselections`

  const isCopy = reportSelectionId && !isNaN(reportSelectionId)

  const url = isCopy ? `${baseUrl}/${reportSelectionId}` : baseUrl

  if (isCopy) {
    return axios
      .post(url, reportSelection, config())
      .then((response) => {
        const createdReportSelection = response.data.data
        return createdReportSelection
      })
      .catch(catchError)
  }

  return axios
    .put(url, reportSelection, config())
    .then((response) => {
      const createdReportSelection = response.data.data
      return createdReportSelection
    })
    .catch(catchError)
}
const deleteReportSelection = (action) => {
  const { companyCode, reportSelectionId } = action
  return axios
    .delete(
      `${config().baseURL}${companyCode}/reportselections/${reportSelectionId}`,
      config()
    )
    .then(returnData)
    .catch(catchError)
}
const getReportSelectionById = (action) => {
  const { companyCode, reportSelectionId } = action
  const url = companyCode
    ? `${config().baseURL}${companyCode}/reportselections/${reportSelectionId}`
    : `${config().baseURL}reportselections/${reportSelectionId}`
  return axios
    .get(url, config())
    .then((response) => {
      const foundReportSelection = response.data.data
      return foundReportSelection
    })
    .catch(catchError)
}
const openReportByReportSelection = async (action) => {
  const {
    companyCode,
    customerCode,
    reportSelection,
    onError,
    onMessage,
    onProgress,
  } = action
  const context = companyCode || customerCode
  const configObj = config()

  fetchEventSource(configObj.baseURL + `${context}/reportdata`, {
    headers: {
      ...configObj.headers,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    openWhenHidden: true,
    body: JSON.stringify(reportSelection),
    onmessage: (event) => {
      if (event.event === 'progress' && onProgress) {
        onProgress(event)
        return
      }
      if (event.event === 'error' && onError) {
        if (onError) {
          onError(event)
        }
        return
      }
      if (event.event === 'keepalive') {
        return
      }
      if (onMessage) {
        onMessage(event)
        return
      }
    },
    onerror: (error) => {
      if (onError) {
        onError(error)
      }
      // Event source library atomaticly retries if error is not thrown
      throw new Error(error)
    },
  }).catch(() => {})
}
const patchReportSelection = (action) => {
  const { companyCode, patch, reportSelectionId } = action
  return axios
    .patch(
      `${companyCode || ''}/reportselections/${reportSelectionId}`,
      patch,
      config()
    )
    .then((response) => {
      const responseValue = response.data.data
      return responseValue
    })
    .catch(catchError)
}

const getReportTags = () =>
  axios
    .get(`/reportselectiontags`, config())
    .then(returnData)
    .catch(catchError)

const createReportTag = ({ tag }) =>
  axios
    .post(`/reportselectiontags`, tag, config())
    .then(returnData)
    .catch(catchError)

const deleteReportTag = ({ tagId }) =>
  axios
    .delete(`/reportselectiontags/${tagId}`, config())
    .then(returnData)
    .catch(catchError)

const editReportTag = ({ tagId, patch }) =>
  axios
    .patch(`/reportselectiontags/${tagId}`, patch, config())
    .then(returnData)
    .catch(catchError)

const reportSelectionApi = {
  createReportSelection,
  deleteReportSelection,
  getReportSelectionById,
  openReportByReportSelection,
  patchReportSelection,
  getReportTags,
  createReportTag,
  deleteReportTag,
  editReportTag,
}

export default reportSelectionApi
