import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { handleError } from 'api/api-utils'
import reportSelectionApi from 'api/ReportSelectionApi'
import reportApi from 'api/ReportApi'

import {
  copyReportSelectionError,
  copyReportSelectionSuccess,
  createReportSelectionError,
  createReportSelectionSuccess,
  createReportTagSuccess,
  deleteReportSelectionSuccess,
  deleteReportTagSuccess,
  editReportTagSuccess,
  getReportSelectionError,
  getReportSelectionSuccess,
  getReportSelectionsError,
  getReportSelectionsSuccess,
  getReportTagsSuccess,
  resetReportSettingsError,
  resetReportSettingsSuccess,
  saveReportSettingsError,
  saveReportSettingsSuccess,
  updateReportSelectionError,
  updateReportSelectionSuccess,
  deleteReportTagError,
} from './actions'
import {
  COPY_REPORT_SELECTION,
  CREATE_REPORT_SELECTION,
  CREATE_REPORT_TAG,
  DELETE_REPORT_SELECTION,
  DELETE_REPORT_TAG,
  EDIT_REPORT_TAG,
  GET_REPORT_SELECTION,
  GET_REPORT_SELECTIONS,
  GET_REPORT_TAGS,
  RESET_REPORT_SETTINGS,
  SAVE_REPORT_SETTINGS,
  UPDATE_REPORT_SELECTION,
} from './constants'
import { createPathPrefix } from 'utils/context'
import { Record } from 'immutable'
import * as Diff from 'immutablediff'

import ReportSelectionUpdateRecord from 'records/ReportSelection/report-selection-update'
import {
  REPORT_SELECTION_TYPE_SYSTEM,
  REPORT_SELECTION_TYPE_CUSTOMER,
} from 'containers/ReportSelections/constants'
import { getReportTypeFromLocation } from 'containers/ReportSelections/utils'
import { clearReportDataPreview } from 'containers/ReportSelections/actions'

const UpdateTagRecord = Record({
  name: null,
  customerId: null,
  companyId: undefined,
  userId: null,
})

export function* copyReportSelection(action) {
  try {
    const {
      reportSelectionId,
      name,
      customerCode,
      companyCode,
      copyPermissions,
      userId,
    } = action
    // TODO: We have to call api here because list endpoint is missing
    // reportDatasource.companies
    // Ideally we would do POST /api/reportselections/{id} with a JSON body that
    // has a field name
    const sourceReportSelection = yield call(
      reportSelectionApi.getReportSelectionById,
      {
        reportSelectionId,
      }
    )

    const copyReportSelection = {
      ...sourceReportSelection,
      id: 0,
      name,
      reportSelectionUsers: copyPermissions
        ? sourceReportSelection.reportSelectionUsers
        : [{ userId, canEdit: true }],
      reportSelectionRoles: copyPermissions
        ? sourceReportSelection.reportSelectionRoles
        : [],
      reportDataSources: sourceReportSelection.reportDataSources.map(
        (reportDatasource) => ({
          ...reportDatasource,
          id: 0,
        })
      ),
      visualizationOverride: sourceReportSelection.visualizationOverride,
    }

    const reportSelection = yield call(
      reportSelectionApi.createReportSelection,
      {
        companyCode,
        customerCode,
        reportSelection: copyReportSelection,
        reportSelectionId,
      }
    )
    yield put(copyReportSelectionSuccess({ reportSelection }))
  } catch (error) {
    yield put(handleError(error, copyReportSelectionError))
  }
}

export function* createReportSelection(action) {
  const {
    companyCode,
    customerCode,
    reportSelection,
    open,
    navigate,
    beta,
    reportSelectionId,
  } = action
  try {
    const createdReportSelection = yield call(
      reportSelectionApi.createReportSelection,
      { companyCode, reportSelection, reportSelectionId }
    )
    yield put(
      createReportSelectionSuccess({
        companyCode,
        customerCode,
        reportSelection: createdReportSelection,
      })
    )
    yield moveTo({
      reportSelection: createdReportSelection,
      customerCode,
      companyCode,
      open,
      navigate,
      beta,
    })
  } catch (error) {
    yield put(handleError(error, createReportSelectionError))
  }
}

export function* createReportTag(action) {
  const { tag } = action
  try {
    const newTag = yield call(reportSelectionApi.createReportTag, { tag })
    yield put(createReportTagSuccess({ tag: newTag }))
  } catch (error) {
    yield put(handleError(error, getReportSelectionsError))
  }
}

export function* deleteReportSelection(action) {
  const { reportSelectionId } = action
  try {
    yield call(reportSelectionApi.deleteReportSelection, {
      companyCode: action.companyCode,
      reportSelectionId,
    })
    yield put(deleteReportSelectionSuccess({ reportSelectionId }))
  } catch (error) {
    yield put(handleError(error, createReportSelectionError))
  }
}

export function* getReports(action) {
  try {
    const reportSelections = yield call(reportApi.getReportSelections, {
      companyCode: action.companyCode,
    })
    yield put(getReportSelectionsSuccess({ reportSelections }))
  } catch (error) {
    yield put(handleError(error, getReportSelectionsError))
  }
}

let payloadCache = []
export function* getReportSelection(action) {
  const { reportId } = action
  const cacheKey = reportId
  if (!payloadCache.includes(cacheKey)) {
    try {
      payloadCache = [...payloadCache, cacheKey]
      const reportSelection = yield call(
        reportSelectionApi.getReportSelectionById,
        {
          reportSelectionId: reportId,
        }
      )
      yield put(getReportSelectionSuccess({ reportId, reportSelection }))
      payloadCache = payloadCache.filter((key) => key !== cacheKey)
    } catch (error) {
      payloadCache = payloadCache.filter((key) => key !== cacheKey)
      yield put(handleError(error, getReportSelectionError, { reportId }))
    }
  }
}

export function moveTo(action) {
  const {
    customerCode,
    companyCode,
    reportSelection: { id },
    open,
    navigate,
    beta,
  } = action
  const endpoint = beta ? 'reports-beta' : 'reports'
  const customerEndpoint = beta ? 'customerreports-beta' : 'customerreports'
  const systemEndpoint = beta
    ? 'systemreportselections-beta'
    : 'systemreportselections'
  const editEndpoint = beta ? `/edit/${id}` : `/${id}`
  const type = getReportTypeFromLocation(window.location)
  if (type === REPORT_SELECTION_TYPE_SYSTEM) {
    navigate(`/${systemEndpoint}${open ? editEndpoint : ''}`)
  } else if (type === REPORT_SELECTION_TYPE_CUSTOMER) {
    navigate(`/${customerCode}/${customerEndpoint}${open ? editEndpoint : ''}`)
  } else {
    const context = createPathPrefix(customerCode, companyCode)
    navigate(`${context}${endpoint}${open ? editEndpoint : ''}`)
  }
}

export function* getReportTags(action) {
  try {
    const tags = yield call(reportSelectionApi.getReportTags)
    yield put(getReportTagsSuccess({ tags }))
  } catch (error) {
    yield put(handleError(error, getReportSelectionsError))
  }
}

export function* deleteReportTag(action) {
  const { tagId } = action
  try {
    yield call(reportSelectionApi.deleteReportTag, { tagId })
    yield put(deleteReportTagSuccess({ tagId }))
  } catch (error) {
    yield put(handleError(error, deleteReportTagError))
  }
}

export function* editReportTag(action) {
  const { tag, oldTag } = action
  try {
    const patch = Diff(new UpdateTagRecord(oldTag), new UpdateTagRecord(tag))
    const newTag = yield call(reportSelectionApi.editReportTag, {
      tagId: oldTag.id,
      patch,
    })
    yield put(editReportTagSuccess({ tag: newTag }))
  } catch (error) {
    yield put(handleError(error, getReportSelectionsError))
  }
}

export function* resetReportSettings(action) {
  const { companyCode, reportSelectionId } = action
  try {
    const patch = [
      {
        op: 'Remove',
        path: '/VisualizationOverride',
      },
    ]

    const reportSelection = yield call(
      reportSelectionApi.patchReportSelection,
      {
        companyCode,
        patch,
        reportSelectionId,
      }
    )
    yield put(
      resetReportSettingsSuccess({ reportSelectionId, reportSelection })
    )
  } catch (error) {
    yield put(handleError(error, resetReportSettingsError))
  }
}

export function* saveReportSettings(action) {
  const { companyCode, reportSelectionId, reportSettings } = action

  const overrideInJSON = JSON.stringify(reportSettings)
  try {
    const patch = [
      {
        op: 'Replace',
        path: '/VisualizationOverride',
        value: overrideInJSON,
      },
    ]
    const reportSelection = yield call(
      reportSelectionApi.patchReportSelection,
      {
        companyCode,
        patch,
        reportSelectionId,
      }
    )
    yield put(
      saveReportSettingsSuccess({
        reportSelection,
        reportSelectionId,
      })
    )
  } catch (error) {
    yield put(handleError(error, saveReportSettingsError))
  }
}

export function* updateReportSelection(action) {
  const {
    companyCode,
    customerCode,
    open,
    navigate,
    beta,
    refetchData,
  } = action
  try {
    // TODO: This diff could probably be optimized. Changing one value should
    // lead to one patch. Now there is 15
    const patch = Diff(
      new ReportSelectionUpdateRecord(action.reportSelection.toJS()),
      new ReportSelectionUpdateRecord(action.updatedReportSelection)
    )

    const reportSelection = yield call(
      reportSelectionApi.patchReportSelection,
      {
        companyCode,
        patch,
        reportSelectionId: action.reportSelection.get('id'),
      }
    )
    yield put(clearReportDataPreview())
    yield put(
      updateReportSelectionSuccess({
        companyCode,
        customerCode,
        reportSelection,
        refetchData,
      })
    )
    if (navigate) {
      yield moveTo({
        reportSelection,
        customerCode,
        companyCode,
        open,
        navigate,
        beta,
      })
    }
  } catch (error) {
    yield put(handleError(error, updateReportSelectionError))
  }
}

export function* reportsSaga() {
  yield all([
    takeLatest(COPY_REPORT_SELECTION, copyReportSelection),
    takeEvery(CREATE_REPORT_SELECTION, createReportSelection),
    takeEvery(DELETE_REPORT_SELECTION, deleteReportSelection),
    takeEvery(GET_REPORT_SELECTION, getReportSelection),
    takeLatest(GET_REPORT_SELECTIONS, getReports),
    takeLatest(GET_REPORT_TAGS, getReportTags),
    takeEvery(CREATE_REPORT_TAG, createReportTag),
    takeEvery(DELETE_REPORT_TAG, deleteReportTag),
    takeLatest(EDIT_REPORT_TAG, editReportTag),
    takeLatest(RESET_REPORT_SETTINGS, resetReportSettings),
    takeLatest(SAVE_REPORT_SETTINGS, saveReportSettings),
    takeLatest(UPDATE_REPORT_SELECTION, updateReportSelection),
  ])
}

export default reportsSaga
